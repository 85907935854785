<template>
  <div style="height: 100%; width: 100%" @click="falseAll">
    <div
      v-if="!isBigScreen && isProjectOverview == 'false'"
      style="height: 100%; width: 100%"
    >
      <!-- <a-switch
      defaultChecked
      @change="changeTheme"
      checkedChildren="dark"
      unCheckedChildren="light"
    />
    <br />
    <br />-->
      <div class="header" style>
        <div
          style
          :class="isCollapseM ? 'isCollapseTHeader' : 'isCollapseFHeader'"
        >
          <div style class="header-content-left" v-if="!isCollapseM">
            <img
              style="vertical-align: middle; margin-left: -20px"
              :src="srcUrl"
              alt
              v-if="srcUrl"
            />

            <!-- <span style>菱感能源</span> -->
          </div>

          <!-- <div style v-if="isCollapseM">
            <img style="width:100%;height:" src="~@/assets/logo.png" alt />
            
          </div> -->
        </div>

        <!-- <div @mouseleave="hideLogout" style="position: relative">
          <div class="user-name" style @mouseover="showLogout">
            {{ userName }}
          </div>
          <div class="down-menu" v-if="isLogout" @click="logOut" style>
            退出登录
          </div>
        </div>
        <div style="clear: both"></div> -->
        <div class="header-content-right">
          <span style="color: #b8c8fa; font-weight: bold; font-size: 24px">{{
            loginTtile
          }}</span>
          <!-- src="~@/assets/收起.png" -->
          <img
            @click="collapaseMenu"
            style="position: absolute; top: 33px; left: 20px; cursor: pointer"
            :src="collapseImg"
            alt
          />
          <!-- <img
            @click="showFull"
            style="position: absolute; top: 33px; left: 310px; cursor: pointer"
            :src="collapseImg"
            alt
          /> -->
          <div
            v-if="
              $route.name !== 'home' &&
              $route.name !== 'userSetting' &&
              $route.name !== 'roleSetting' &&
              $route.name !== 'groupSetting'
            "
            style="
              width: 43px;
              height: 43px;
              background: #3f6df8;
              border-radius: 2px;
              text-align: center;
              position: absolute;
              top: 25px;
              left: 90px;
            "
          ></div>
          <img
            v-if="
              $route.name !== 'home' &&
              $route.name !== 'userSetting' &&
              $route.name !== 'roleSetting' &&
              $route.name !== 'groupSetting'
            "
            style="position: absolute; top: 33px; left: 96px"
            src="~@/assets/地球.png"
            alt
          />
          <div
            v-if="
              $route.name !== 'home' &&
              $route.name !== 'userSetting' &&
              $route.name !== 'roleSetting' &&
              $route.name !== 'groupSetting'
            "
            @click.stop="clickSelect1($event)"
            style="
              width: 190px;
              height: 43px;
              line-height: 43px;
              background: rgba(255, 255, 255, 0.1);
              border-radius: 0 2px 2px 0;
              color: white;
              padding-left: 13px;
              padding-right: 13px;
              position: absolute;
              top: 25px;
              left: 133px;
              cursor: pointer;
              z-inex: 9999999;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <!-- @click="isShowSelect1 = !isShowSelect1" -->
            <span style="margin-right: 10px; font-size: 14px">{{
              currentProjectName
            }}</span>
            <i class="el-icon-arrow-down"></i>
            <div
              v-if="isShowSelect1"
              style="
                position: absolute;
                z-index: 9999999;
                width: 190px;

                top: 45px;
                left: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                background: #213865;
              "
            >
              <!-- max-height: 116px; -->
              <div
                style=""
                class="sel-option"
                :class="{
                  activeOption1: currentProjectName === item.projectName,
                }"
                v-for="(item, index) in projectArray"
                :key="index"
                @click="clickOption1(item)"
              >
                {{ item.projectName }}
              </div>
              <!-- <div style="" class="sel-option" @click="clickOption('功率因数')">
                功率因数
              </div> -->
            </div>
          </div>

          <div
            style="
              width: 154px;
              height: 43px;
              line-height: 43px;
              background: rgba(255, 255, 255, 0.1);
              border-radius: 0 2px 2px 0;
              color: white;
              padding-left: 13px;
              position: absolute;
              top: 25px;
              right: 250px;
              cursor: pointer;
            "
          >
            <!-- @click="isShowSelect2 = !isShowSelect2" -->
            <span
              @click="clickSelect2($event)"
              style="margin-right: 10px; font-size: 14px"
              >{{ languageP }}</span
            >
            <i @click="clickSelect2($event)" class="el-icon-arrow-down"></i>
            <div
              v-if="isShowSelect2"
              style="
                position: absolute;
                z-index: 999;
                width: 154px;

                top: 45px;
                left: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                background: #213865;
              "
            >
              <!-- max-height: 116px; -->
              <div
                style=""
                class="sel-option"
                :class="{ activeOption1: languageP === item }"
                v-for="(item, index) in dataOption2"
                :key="index"
                @click="clickOption2(item)"
              >
                {{ item }}
              </div>
            </div>
            <!-- <div style="width: 154px;height: 43px;line-height: 43px;background:white;position:absolute;top:43px;left:0;z-index:11">
              简体中文
            </div>
            <div style="width: 154px;height: 43px;line-height: 43px;background:white;position:absolute;top:86px;left:0;z-index:11">
              ENGLISH
            </div> -->
          </div>

          <div
            style="
              position: absolute;
              top: 15px;
              right: 165px;
              width: 60px;
              height: 60px;
            "
          >
            <img
              style="position: absolute; top: 5px; left: 15px"
              src="~@/assets/形状.png"
              alt
            />
            <div
              style="
                color: white;
                height: 20px;
                width: 60px;
                top: 40px;
                left: 0px;
                line-height: 20px;
                font-size: 14px;
                position: absolute;
              "
            >
              系统警报
            </div>
          </div>

          <div
            style="
              position: absolute;
              top: 15px;
              right: 84px;
              width: 60px;
              height: 60px;
            "
          >
            <img
              style="position: absolute; top: 5px; left: 15px"
              src="~@/assets/日志.png"
              alt
            />
            <div
              style="
                color: white;
                height: 20px;
                width: 60px;
                top: 40px;
                left: 0px;
                line-height: 20px;
                font-size: 14px;
                position: absolute;
              "
            >
              系统日志
            </div>
          </div>

          <div
            style="
              position: absolute;
              top: 15px;
              right: 14px;
              width: 60px;
              height: 60px;
              cursor: pointer;
            "
            @click="isShowSelect3 = !isShowSelect3"
          >
            <img
              v-if="!isShowSelect3"
              style="position: absolute; top: 5px; left: 15px"
              src="~@/assets/用户.png"
              alt
            />
            <img
              v-else
              style="position: absolute; top: 5px; left: 15px"
              src="~@/assets/用户select.png"
              alt
            />
            <div
              v-if="!isShowSelect3"
              style="
                color: white;
                height: 20px;
                width: 60px;
                top: 40px;
                left: 0px;
                line-height: 20px;
                font-size: 14px;
                position: absolute;
                text-align: center;
              "
            >
              {{ userName }}
            </div>
            <div
              v-else
              style="
                color: #3f6df8;
                height: 20px;
                width: 60px;
                top: 40px;
                left: 0px;
                line-height: 20px;
                font-size: 14px;
                position: absolute;
                text-align: center;
              "
            >
              {{ userName }}
            </div>

            <div
              v-if="isShowSelect3"
              style="
                position: absolute;
                z-index: 999;
                width: 120px;
                top: 60px;
                left: -70px;
                padding-top: 10px;
                padding-bottom: 10px;
                background: #213865;
              "
            >
              <!-- max-height: 116px; -->
              <div style="" class="sel-option" @click="changePassord">
                修改密码
              </div>
              <div style="" class="sel-option" @click="logOut">注销登录</div>
            </div>
          </div>
          <!-- <div
            style="
              position: absolute;
              top: 55px;
              right: 84px;
              color: white;
              height: 20px;
              line-height: 20px;
              font-size: 14px;
            "
          >
            <img style="" src="~@/assets/日志.png" alt />
            系统日志
          </div>
          <div
            style="
              position: absolute;
              top: 55px;
              right: 14px;
              color: white;
              height: 20px;
              line-height: 20px;
              font-size: 14px;
              text-align: center;
              width: 60px;
              cursor: pointer;
            "
          >
            <img style="" src="~@/assets/用户.png" alt />
            {{ userName }}
          </div> -->
        </div>
      </div>

      <div
        style="
          display: flex;
          height: calc(100% - 96px);
          background: #082254;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
        "
      >
        <div
          v-if="isSide"
          :class="isCollapseM ? 'isCollapseTBody' : 'isCollapseFBody'"
          style="overflow: auto"
        >
          <!-- style="width: 250px; flex: none; height: 100%" -->
          <!-- <a-menu
          style="width: 256px;height:100%"
          :defaultSelectedKeys="['channelData']"
          :defaultOpenKeys="['sub1']"
          mode="inline"
          :theme="theme"
          :selectedKeys="[current]"
          @click="handleClick"
        >
          <a-menu-item key="channelData">
            通道数据查询
          </a-menu-item>
          <a-menu-item key="original">
            原始日志查询
          </a-menu-item>
        </a-menu> -->
          <el-menu
            style="overflow: auto"
            :default-active="activeIndex"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            :collapse="isCollapse"
            background-color="#082254"
            text-color="#fff"
            active-text-color="#fff"
            @select="handleSelect"
            :router="isRouter"
            :collapse-transition="isCollapseAnimate"
          >
            <template v-for="(item, i) in menuSide">
              <template v-if="item.children.length === 0">
                <el-menu-item :index="item.menuId" :route="item.path" :key="i">
                  <i
                    :class="iconObject[item.menuId]"
                    style="
                      margin-left: 10px;
                      margin-right: 10px;
                      vertical-align: middle;
                      font-size: 20px;
                      color: white;
                      opacity: 0.8;
                    "
                  ></i>
                  <span slot="title" style="opacity: 0.8">{{ item.name }}</span>
                </el-menu-item>
              </template>
              <template v-if="item.children.length > 0">
                <el-submenu :key="i" :index="item.menuId">
                  <template slot="title">
                    <i
                      :class="iconObject[item.menuId]"
                      style="
                        margin-left: 10px;
                        margin-right: 10px;
                        vertical-align: middle;
                        font-size: 20px;
                        color: white;
                        opacity: 0.8;
                      "
                    ></i>
                    <span style="font-size: 16px; opacity: 0.8" slot="title">{{
                      item.name
                    }}</span>
                  </template>
                  <el-menu-item
                    style="font-size: 16px; opacity: 0.8"
                    v-for="child in item.children"
                    :key="child.menuId"
                    :index="child.menuId"
                    :route="child.path"
                  >
                    <!-- <i :class="child.icon"></i> -->
                    {{ child.name }}
                  </el-menu-item>
                </el-submenu>
              </template>
            </template>

            <!--<el-menu-item index="home" route="/home">
              <i
                class="iconfont icon-ditu"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                  opacity: 0.8;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">能耗地图</span>
            </el-menu-item>
            <el-menu-item index="overall" route="/overall">
              <i
                class="iconfont icon-integral"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                  opacity: 0.8;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">项目概览</span>
            </el-menu-item>
            <el-menu-item index="actual" route="/actual">
              <i
                class="iconfont icon-baobiao"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                  opacity: 0.8;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">实时监测</span>
            </el-menu-item>
            <el-submenu index="resourceManagement">
              <template slot="title">
                <i
                  class="iconfont icon-NMStubiao-"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span style="font-size: 16px; opacity: 0.8" slot="title"
                  >能源管理</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="resourceOverall"
                route="/resource-management/resource-overall"
                >能耗总览</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="resourceAnalyze"
                route="/resource-management/resource-analyze"
                >能耗分析</el-menu-item
              > -->
            <!-- <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="resourceTuopu"
                route="/resource-management/resource-tuopu"
                >能源拓扑</el-menu-item
              > -->
            <!-- <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="resourceSanki"
                route="/resource-management/resource-sanki"
                >能源流向</el-menu-item
              >
            </el-submenu>
            <el-submenu index="energyComment">
              <template slot="title">
                <i
                  class="iconfont icon-fenxi"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span style="font-size: 16px; opacity: 0.8" slot="title"
                  >能效评估</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="coldSystem"
                route="/energy-comment/cold-system"
                >冷冻系统</el-menu-item
              > -->
            <!-- <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="coldMain"
                route="/energy-comment/cold-main"
                >冷冻主机</el-menu-item
              > -->
            <!-- </el-submenu>
            <el-submenu index="dataAnalyze">
              <template slot="title">
                <i
                  class="iconfont icon-fenxi"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span style="font-size: 16px; opacity: 0.8" slot="title"
                  >数据分析</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="dataSearch"
                route="/data-analyze/data-search"
                >数据查询</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="categoryAnalyze"
                route="/data-analyze/category-analyze"
                >类比分析</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="timeAnalyze"
                route="/data-analyze/time-analyze"
                >时比分析</el-menu-item
              >
            </el-submenu> -->
            <!-- <el-menu-item index="6">
              <i
                class="iconfont icon-fenxi1"
                style="
                  opacity: 0.8;
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">分析工具</span>
            </el-menu-item> -->
            <!-- <el-submenu index="analyzeTool">
              <template slot="title">
                <i
                  class="iconfont icon-fenxi"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span style="font-size: 16px; opacity: 0.8" slot="title"
                  >分析工具</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="aboutAnalyze"
                route="/analyze-tool/about-analyze"
                >相关性分析</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="scatter"
                route="/analyze-tool/scatter"
                >散点图</el-menu-item
              >
            </el-submenu> -->
            <!-- <el-menu-item index="7">
              <i
                class="iconfont icon-baobiaoguanli"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                  opacity: 0.8;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">数据报表</span>
            </el-menu-item> -->
            <!-- <el-submenu index="dataReport">
              <template slot="title">
                <i
                  class="iconfont icon-fenxi"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span style="font-size: 16px; opacity: 0.8" slot="title"
                  >数据报表</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="reportSearch"
                route="/data-report/report-search"
                >报表查询</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="reportManage"
                route="/data-report/report-manage"
                >报表管理</el-menu-item
              >
            </el-submenu>
            <el-submenu index="alarmManage">
              <template slot="title">
                <i
                  class="iconfont icon-alert"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span slot="title" style="font-size: 16px; opacity: 0.8"
                  >告警管理</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="alarmOverall"
                route="/alarm-manage/alarm-overall"
                >告警总览</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="alarmSearch"
                route="/alarm-manage/alarm-search"
                >告警查询</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="alarmSet"
                route="/alarm-manage/alarm-set"
                >告警设置</el-menu-item
              >
            </el-submenu>
            <el-submenu index="sysSetting">
              <template slot="title">
                <i
                  class="iconfont icon-set"
                  style="
                    margin-left: 10px;
                    margin-right: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    color: white;
                    opacity: 0.8;
                  "
                ></i>
                <span slot="title" style="font-size: 16px; opacity: 0.8"
                  >系统设置</span
                >
              </template>
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="userSetting"
                route="/sys-setting/user-setting"
                >用户设置</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="roleSetting"
                route="/sys-setting/role-setting"
                >角色设置</el-menu-item
              >
              <el-menu-item
                style="font-size: 16px; opacity: 0.8"
                index="groupSetting"
                route="/sys-setting/group-setting"
                >项目组</el-menu-item
              >
            </el-submenu> -->
            <!-- <el-menu-item index="9">
              <i
                class="iconfont icon-gongdan"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                  opacity: 0.8;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">工单管理</span>
            </el-menu-item>

            <el-menu-item index="10">
              <i
                class="iconfont icon-set"
                style="
                  margin-left: 10px;
                  margin-right: 10px;
                  vertical-align: middle;
                  font-size: 20px;
                  color: white;
                  opacity: 0.8;
                "
              ></i>
              <span slot="title" style="opacity: 0.8">系统设置</span>
            </el-menu-item> -->
            <!-- <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title">导航一</span>
              </template>
              <el-menu-item-group>
                <span slot="title">分组一</span>
                <el-menu-item index="1-1">选项1</el-menu-item>
                <el-menu-item index="1-2">选项2</el-menu-item>
                <el-menu-item index="1-3">选项2</el-menu-item>
                <el-menu-item index="1-4">选项2</el-menu-item>
                <el-menu-item index="1-5">选项2</el-menu-item>
                <el-menu-item index="1-6">选项2</el-menu-item>
                <el-menu-item index="1-7">选项2</el-menu-item>
                <el-menu-item index="1-8">选项2</el-menu-item>
                <el-menu-item index="1-9">选项2</el-menu-item>
                <el-menu-item index="1-10">选项2</el-menu-item>
                <el-menu-item index="1-11">选项2</el-menu-item>
                <el-menu-item index="1-12">选项2</el-menu-item>
                <el-menu-item index="1-13">选项2</el-menu-item>
                <el-menu-item index="1-14">选项2</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group title="分组2">
                <el-menu-item index="1-3">选项3</el-menu-item>
              </el-menu-item-group>
              <el-submenu index="1-4">
                <span slot="title">选项4</span>
                <el-menu-item index="1-4-1">选项1</el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-menu-item index="2">
              <i class="el-icon-menu"></i>
              <span slot="title">导航二</span>
            </el-menu-item>
            <el-menu-item index="3" disabled>
              <i class="el-icon-document"></i>
              <span slot="title">导航三</span>
            </el-menu-item>
            <el-menu-item index="4">
              <i class="el-icon-setting"></i>
              <span slot="title">导航四</span>
            </el-menu-item> -->
          </el-menu>
        </div>
        <!-- :class="isPadding ? 'isPaddingTBody' : 'isPaddingFBody'" -->
        <div style="flex: 1; overflow: hidden" :class="classObject">
          <router-view :key="key" />
          <!-- <iframe
            :src="url"
            style="height: 100%; width: 100%"
            frameborder="0"
          ></iframe> -->
        </div>
      </div>
    </div>
    <div
      v-if="isBigScreen"
      style="height: 100%; width: 100%"
      class="big-screen"
    >
      <!-- <full-screen v-if="loginTtile === '菱感能源中心'"></full-screen>
      <full-screenhb v-else></full-screenhb> -->
      <full-screenhb></full-screenhb>
    </div>
    <div
      v-if="isProjectOverview == 'true'"
      style="height: 100%; width: 100%; overflow: hidden"
      class="big-screen"
    >
      <!-- <full-screen v-if="loginTtile === '菱感能源中心'"></full-screen>
      <full-screenhb v-else></full-screenhb> -->
      <project-overview></project-overview>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="changePasswordVisible"
      :close-on-click-modal="false"
      width="30%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item label="登陆账号：" prop="userId">
          <el-input v-model="ruleForm.userId" disabled></el-input>
        </el-form-item>
        <el-form-item label="旧 密 码：" prop="pwd">
          <el-input show-password v-model="ruleForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="新 密 码：" prop="newPwd">
          <el-input show-password v-model="ruleForm.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="确 认 密 码：" prop="checkNewPwd">
          <el-input show-password v-model="ruleForm.checkNewPwd"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import fullScreen from "@/views/layoutFull";
import fullScreenhb from "@/views/layoutFullHB";
import projectOverview from "@/components/projectOverview/projectOverview";
// import * as utils from "@/utils/utils";
// import axios from "axios";
import {
  getHomeSrc,
  getHomeTitle,
  getMenu,
  getProjects,
  // getBigElecActual,
  // getBigElecM,
  // getBigWaterM,
  // getBigGasM,
  setProject,
  changePassword,
  // getBigHeatM,
  // getBigSteamM,
  getYearChart,
  getYearChartWater,
  getYearChartAir,
  // getYearChartHeat,
  getYearChartSteam,
  loginOut,
} from "@/request/apiLg";
import * as echarts from "echarts";

export default {
  name: "",
  props: [""],
  data() {
    var validatePass = (rule, value, callback) => {
      if (!/[A-Za-z0-9@]{6,}/.test(this.ruleForm.checkNewPwd)) {
        callback(new Error("密码只支持大小写字母，@和数字，至少6位"));
      } else {
        this.$refs.ruleForm.validateField("checkNewPwd");
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var dataAxis = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var data = [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 579];
    return {
      srcUrl: "",
      loginTtile: "",
      iconObject: {
        home: "iconfont icon-ditu",
        overall: "iconfont icon-integral",
        actual: "iconfont icon-baobiao",
        resourceManagement: "iconfont icon-NMStubiao-",
        energyComment: "iconfont icon-fenxi",
        dataAnalyze: "iconfont icon-fenxi",
        dataReport: "iconfont icon-gongdan",
        alarmManage: "iconfont icon-alert",
        sysSetting: "iconfont icon-set",
        baseConfig: "iconfont icon-set",
        orderManage: "iconfont icon-gongdan",
        algStore: "iconfont icon-baobiao",
        algService: "iconfont icon-baobiao",
      },
      unit2: "(kWh)",
      isModalChartShow: false,
      isGNshow: false,
      isNHshow: false,
      isYWshow: false,
      isZLshow: false,
      dataUsed: [],
      monthDataAxis: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      elecuse: [0, 0, 0, 0, 0, 0, 0],
      wateruse: ["-", "-", "-", "-", "-", "-", "-"],
      airuse: ["-", "-", "-", "-", "-", "-", "-"],
      heatuse: ["-", "-", "-", "-", "-", "-", "-"],
      elecprovide: ["-", "-", "-", "-", "-", "-", "-"],
      iceprovide: ["-", "-", "-", "-", "-", "-", "-"],
      heatprovide: ["-", "-", "-", "-", "-", "-", "-"],
      isCollapseAnimate: false,
      mapOption: "全部",
      isShowSelectMap: false,
      mapName: "全部",
      dataOptionMap: [
        "全部",
        "能源站",
        "光伏电站",
        "储能电站",
        "商业用户",
        "工业用户",
      ],
      ruleForm: {
        userId: "",
        pwd: "",
        newPwd: "",
        checkNewPwd: "",
      },
      rules: {
        userId: [{ required: true, message: "请输入账号", trigger: "blur" }],
        newPwd: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkNewPwd: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
      },
      changePasswordVisible: false,
      isShowSelect3: false,
      dataOption1: [],
      dataOption2: ["简体中文", "English"],
      // dataOption3: ["修改密码", "注销登录"],
      languageP: "简体中文",
      projectName: "文体中心",
      isShowSelect1: false,
      isShowSelect2: false,
      collapseImg: `${require("@/assets/收起.png")}`,
      allElecP: "",
      allElecProvide: "-- --",
      isBgDeep: true,
      activeIndex: "home",
      isPadding: true,
      isRouter: true,
      isActivenNH: 1,
      isActivenGN: 1,
      isCollapseM: false,
      showModal: false,
      isClick1: false,
      isClick2: false,
      isClick3: false,
      userName: "",
      valueP: "1",
      menuSide: [],
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "选项2",
          label: "2",
        },
        {
          value: "选项3",
          label: "3",
        },
      ],
      // isBigScreen: false,
      url: "http://johpcyvc.cloudpreview.cloud.techsel.net/#/outofLogin?loginInfo=dXNlcm5hbWU9YWRtaW4mcGFzc3dvcmQ9MTIzNDU2",
      isCollapse: false,
      current: "",
      currentKey: "",
      theme: "dark",
      isSide: true,
      isLogout: false,
      chartModalOption: {
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "30px",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#999",
          //   },
          // },
        },
        // toolbox: {
        //   feature: {
        //     dataView: { show: true, readOnly: false },
        //     magicType: { show: true, type: ["line", "bar"] },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        legend: {
          data: ["总用电量", "预测总用电量", "温度"],
          right: 100,
          bottom: 0,
          textStyle: {
            color: "#bcccff",
          },
        },
        xAxis: [
          {
            type: "category",
            data: [
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#BCCCFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "耗能",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#BCCCFF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            type: "value",
            name: "温度",
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#BCCCFF",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                color: ["#BCCCFF"],
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63,109,248,0.3)"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "总用电量",
            type: "bar",
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "预测总用电量",
            type: "line",
            // yAxisIndex: 1,
            smooth: true,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#41F39A", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(65, 243, 154, 0.47)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "温度",
            type: "line",
            yAxisIndex: 1,
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      },
      chartOption2: {
        tooltip: {
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "10px",
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 5,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
              },
            },
            data: data,
          },
        ],
      },
      chartPieOption: {
        color: ["#5492D1", "#46BCEE", "#ED9C00", "#53EFC3", "#1464C9"],
        grid: {
          // containLabel: true,
          // top: "10px",
          // left: "5px",
          // right: "10px",
          // bottom: "10px",
        },
        tooltip: {
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          trigger: "item",
          formatter: "{b}: {d}%",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 0,
          top: 20,
          bottom: 20,
          data: [
            {
              name: "能源站",
              textStyle: {
                color: "#1464C9",
              },
            },
            {
              name: "光伏电站",
              textStyle: {
                color: "#53EFC3",
              },
            },
            {
              name: "储能电站",
              textStyle: {
                color: "#ED9C00",
              },
            },
            {
              name: "商业用户",
              textStyle: {
                color: "#46BCEE",
              },
            },
            {
              name: "工业用户",
              textStyle: {
                color: "#5492D1",
              },
            },
          ],
          // data: data.legendData,
          // selected: data.selected,
          // textStyle: {
          //   color: "#bcccff",
          // },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["25%", "70%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              position: "outside",
              // alignTo: "edge",
              // formatter: "{per|{c} %}\n",
              formatter: (data) => {
                if (data.name === "工业用户") {
                  return `{a|${data.percent} %\n}`;
                } else if (data.name === "商业用户") {
                  return `{b|${data.percent} %\n}`;
                } else if (data.name === "储能电站") {
                  return `{c|${data.percent} %\n}`;
                } else if (data.name === "光伏电站") {
                  return `{d|${data.percent} %\n}`;
                } else if (data.name === "能源站") {
                  return `{e|${data.percent} %\n}`;
                }
              },
              // minMargin: 5,
              // edgeDistance: 10,
              // lineHeight: 15,
              rich: {
                a: {
                  fontSize: 14,
                  color: "#5492D1",
                  // fontWeight: "bold",
                },
                b: {
                  fontSize: 14,
                  color: "#46BCEE",
                  // fontWeight: "bold",
                },
                c: {
                  fontSize: 14,
                  color: "#ED9C00",
                  // fontWeight: "bold",
                },
                d: {
                  fontSize: 14,
                  color: "#53EFC3",
                  // fontWeight: "bold",
                },
                e: {
                  fontSize: 14,
                  color: "#1464C9",
                  // fontWeight: "bold",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14,
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
              length2: 20,
              // smooth: true,
              // minTurnAngle: 50,
            },
            data: [
              { value: 15, name: "工业用户" },
              { value: 15, name: "商业用户" },
              { value: 40, name: "储能电站" },
              { value: 20, name: "光伏电站" },
              { value: 10, name: "能源站" },
            ],
          },
        ],
      },
      chartOptionCircle1: {
        // grid: {
        //   containLabel: true,
        //   top: "5px",
        //   left: "5px",
        //   right: "5px",
        //   bottom: "5px",
        // },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#F2CA3F",
                  },
                  {
                    offset: 1,
                    color: "#ED814C",
                  },
                ]),
                // [
                //   [
                //     1,
                //     new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //       {
                //         offset: 0,
                //         color: "#F2CA3F",
                //       },
                //       {
                //         offset: 1,
                //         color: "#ED814C",
                //       },
                //     ]),
                //   ],
                // ],
              },
            },
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [
                    1,
                    // new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#F2CA3F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#ED814C",
                    //   },
                    // ]),
                    "rgba(242, 202, 63, 0.3)",
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 20,
                name: "故障率",
                title: {
                  offsetCenter: ["0%", "25%"],
                },
                detail: {
                  offsetCenter: ["0%", "-25%"],
                },
              },
              // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
            ],
            title: {
              fontSize: 18,
              color: "#F2CA3F",
            },
            detail: {
              width: 50,
              height: 18,
              fontSize: 18,
              color: "#F2CA3F",
              // borderColor: "auto",
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: "{value}%",
            },
          },
        ],
      },
      chartOptionCircle2: {
        // grid: {
        //   containLabel: true,
        //   top: "5px",
        //   left: "5px",
        //   right: "5px",
        //   bottom: "5px",
        // },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#3FDFF2",
                  },
                  {
                    offset: 1,
                    color: "#3F68F2",
                  },
                ]),
                // [
                //   [
                //     1,
                //     new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                //       {
                //         offset: 0,
                //         color: "#F2CA3F",
                //       },
                //       {
                //         offset: 1,
                //         color: "#ED814C",
                //       },
                //     ]),
                //   ],
                // ],
              },
            },
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [
                    1,
                    // new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#F2CA3F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#ED814C",
                    //   },
                    // ]),
                    "rgba(4, 23, 76, 0.7)",
                  ],
                ],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 20,
                name: "完成率",
                title: {
                  offsetCenter: ["0%", "25%"],
                },
                detail: {
                  offsetCenter: ["0%", "-25%"],
                },
              },
              // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
            ],
            title: {
              fontSize: 18,
              color: "#3FDFF2",
            },
            detail: {
              width: 50,
              height: 18,
              fontSize: 18,
              color: "#3FDFF2",
              // borderColor: "auto",
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: "{value}%",
            },
          },
        ],
      },
    };
  },

  components: {
    // fullScreen,
    fullScreenhb,
    projectOverview,
  },
  created() {
    // window.localStorage.setItem("isLogin", '2')
    // debugger
    // if (localStorage.getItem("MENU")) {
    //   let temp1 = JSON.parse(localStorage.getItem("MENU"));
    //   // this.$router.options.routes[0].children = menu;
    //   // console.log(this.$router.options.routes);

    //   // this.$router.addRoutes(this.$router.options.routes);
    //   console.log("temp1", temp1);
    //   const menu = utils.formatRoutes(temp1);
    //   let temp = [
    //     {
    //       path: "/",
    //       name: "layout",
    //       redirect: "home",
    //       component: () => import("@/layout"),
    //       meta: { title: "首页" },
    //       children: [],
    //     },
    //   ];
    //   console.log("menu", menu);
    //   temp[0].children = menu;

    //   this.$router.addRoutes(temp);
    // }
    // getProjects().then((res1) => {
    //   if (res1.data) {
    //     this.dataOption1 = res1.data.data;
    //     this.projectName = res1.data.data[0].projectName;
    //     this.mutationsChangeId(res1.data.data[0].projectId);
    //     setProject(res1.data.data[0].projectId).then((res) => {
    //       if (res.data.code === 0) {

    // this.dataOption1 = res1.data.data;
    // this.projectName = res1.data.data[0].projectName;
    this.mutationsChangeName(
      JSON.parse(window.localStorage.getItem("PROJECTNAME"))
    );
    this.mutationsChangeId(
      JSON.parse(window.localStorage.getItem("PROJECTID"))
    );
    getProjects().then((res1) => {
      if (res1.data.code === 0) {
        // this.dataOption1 = res1.data.data;
        // this.projectName = res1.data.data[0].projectName;
        // this.mutationsChangeId(res1.data.data[0].projectId);
        // this.mutationsChangeName(res1.data.data[0].projectName);
        this.mutationsChangeArray(res1.data.data);
        setProject(JSON.parse(window.localStorage.getItem("PROJECTID"))).then(
          (res2) => {
            if (res2.data.code === 0) {
              console.log(res2);
              if (
                this.$route.name === "actual" ||
                this.$route.name == "equipSys" ||
                this.$route.name == "elecMonitor"
              ) {
                this.isPadding = false;
              } else {
                this.isPadding = true;
              }
              if (
                this.$route.name == "actual" ||
                this.$route.name == "equipSys" ||
                this.$route.name == "elecMonitor" ||
                this.$route.name == "home" ||
                this.$route.name == "overall" ||
                this.$route.name == "heatingSystem" ||
                this.$route.name == "heatingSystemJk" ||
                this.$route.name == "coldSystemJk" ||
                this.$route.name == "projectOverallZGC" ||
                this.$route.name == "projectOverallJKDJ" ||
                this.$route.name == "workshopMonitor" ||
                this.$route.name == "workshopMonitor2" ||
                this.$route.name == "actualTimeWorkshopMonitor" ||
                this.$route.name == "actualTimeWorkshopMonitor2"
              ) {
                this.isBgDeep = true;
              } else {
                this.isBgDeep = false;
              }
              this.activeIndex = this.$route.name;

              this.userName = localStorage.getItem("userName");
              let self = this;
              this.$nextTick(function () {
                document.addEventListener("keyup", function (e) {
                  //此处填写你的业务逻辑即可
                  // debugger
                  if (e.key === "Escape") {
                    self.closeScreenFull();
                  }
                });
              });
            }
          }
        );
      }
    });

    //       }
    //     });

    //   } else {
    //     this.$message.error(res1.data.msg);
    //   }
    // });

    this.menuSide = JSON.parse(localStorage.getItem("MENU"));
    window.addEventListener("resize", this.resizeHandler);
    setTimeout(() => {
      this.resizeHandler();
    });
    getMenu().then((res) => {
      if (res.data) {
        // 获取角色列表
        let sysRoles = JSON.parse(localStorage.getItem("sysRoles"));

        // 判断角色是否有中关村界面的权限
        let zgcRoleArr = ["中关村", "zgc"];
        let hasRoles = [];
        if (sysRoles) {
          hasRoles = sysRoles.find((item) => {
            if (
              zgcRoleArr.find((jtem) => {
                if (item.roleName.indexOf(jtem) > -1) {
                  return jtem;
                }
              })
            ) {
              return item;
            }
          });
        }
        let account = JSON.parse(localStorage.getItem("account") || "{}");
        let index = res.data.data.findIndex((item) => item.name === "项目概览");
        if (
          index > -1 &&
          account &&
          (account.username.indexOf("zgc") > -1 ||
            account.username.indexOf("baidu") > -1 ||
            hasRoles)
        ) {
          res.data.data[index].component = "/projectOverall/zgc";
        }
        // console.log("1");
        // res.data.data[0].component = '/homeMap/index'
        // let aa = JSON.stringify(res.data.data);
        // console.log("aa", aa);
        window.localStorage.setItem("MENU", JSON.stringify(res.data.data));
      }
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  computed: {
    ...mapState({
      isBigScreen: (state) => state.isBigScreen,
      isProjectOverview: (state) => state.isProjectOverview,
      currentProjectId: (state) => state.currentProjectId,
      currentProjectName: (state) => state.currentProjectName,
      projectArray: (state) => state.projectArray,
    }),
    chartOption1() {
      return {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}月: {c}kWh",
        },
        grid: {
          containLabel: true,
          top: "10px",
          left: "5px",
          right: "5px",
          bottom: "10px",
        },
        xAxis: {
          data: this.monthDataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#BCCCFF",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.5)"],
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#BCCCFF",
            },
          },
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63,109,248,0.3)"],
              width: 1,
              type: "dashed",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#55ffbc" },
                { offset: 0.62, color: "#47A5E1" },
                { offset: 1, color: "#3F6DF8" },
              ]),
              borderRadius: 5,
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55ffbc" },
                  { offset: 0.62, color: "#47A5E1" },
                  { offset: 1, color: "#3F6DF8" },
                ]),
              },
            },
            data: this.dataUsed,
          },
        ],
      };
    },
    key() {
      return this.$route.fullPath;
    },
    classObject() {
      let islink = window.localStorage.getItem("islink");
      if (islink) {
        return "islink";
      }
      // return {
      //   active: this.isActive && !this.error,
      //   "text-danger": this.error && this.error.type === "fatal",
      // };
      let temp1 = "";
      let temp2 = "";
      if (this.isPadding) {
        temp1 = "isPaddingTBody";
      } else {
        temp1 = "isPaddingFBody";
      }
      if (this.isBgDeep) {
        temp2 = "isBgDeepT";
      } else {
        temp2 = "isBgDeepF";
      }
      return temp1 + " " + temp2;
    },
    // color() {
    //   return ["#00d1d1", "#2aadfe", "#FBD758", "pink", "#e4b989", "#ef5a5a"];
    // },
    // chartOption() {
    //   var dataAxis = [
    //     "01",
    //     "02",
    //     "03",
    //     "04",
    //     "05",
    //     "06",
    //     "07",
    //     "08",
    //     "09",
    //     "10",
    //     "11",
    //     "12",
    //   ];
    //   var data = [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 579];
    //   // var yMax = 600;
    //   // var dataShadow = [];
    //   return {
    //     // title: {
    //     //   text: "特性示例：渐变色 阴影 点击缩放",
    //     //   subtext: "Feature Sample: Gradient Color, Shadow, Click Zoom",
    //     // },
    //     xAxis: {
    //       data: dataAxis,
    //       axisLabel: {
    //         inside: true,
    //         textStyle: {
    //           color: "#fff",
    //         },
    //       },
    //       axisTick: {
    //         show: false,
    //       },
    //       axisLine: {
    //         show: false,
    //       },
    //       z: 10,
    //     },
    //     yAxis: {
    //       axisLine: {
    //         show: false,
    //       },
    //       axisTick: {
    //         show: false,
    //       },
    //       axisLabel: {
    //         textStyle: {
    //           color: "#999",
    //         },
    //       },
    //     },
    //     dataZoom: [
    //       {
    //         type: "inside",
    //       },
    //     ],
    //     series: [
    //       {
    //         type: "bar",
    //         itemStyle: {
    //           // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //           //   { offset: 0, color: "#83bff6" },
    //           //   { offset: 0.5, color: "#188df0" },
    //           //   { offset: 1, color: "#188df0" },
    //           // ]),
    //         },
    //         emphasis: {
    //           itemStyle: {
    //             // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //             //   { offset: 0, color: "#2378f7" },
    //             //   { offset: 0.7, color: "#2378f7" },
    //             //   { offset: 1, color: "#83bff6" },
    //             // ]),
    //           },
    //         },
    //         data: data,
    //       },
    //     ],
    //   };
    // },

    // current(){
    //   return this.$route.name;
    // },
    // currentKey(){
    //   return this.$route.name;
    // }
  },

  beforeMount() {},

  mounted() {
    getHomeSrc().then((res) => {
      this.srcUrl = res.data.msg;
      console.log("this.srcUrl", this.srcUrl);
    });
    getHomeTitle().then((res) => {
      this.loginTtile = res.data.msg;
      window.document.title = res.data.msg;
    });
    let account = JSON.parse(localStorage.getItem("account") || "{}");
    if (account?.username) {
      this.ruleForm.userId = account.username;
    }
    // let data = {
    //   energyAttrId: "P",
    //   energyTypeId: "elec",
    //   projectId: "wtzx",
    // };
    // getBigElecP(data).then((res) => {
    //   // debugger
    //   if (res.data) {
    //     if (res.data.data === null) {
    //       this.allElecP = "-- --";
    //     } else {
    //       this.allElecP = res.data.data;
    //     }
    //   } else {
    //     this.$message.error(res.data.msg);
    //   }
    // });
    // this.init();
    // sessionStorage.setItem('accessToken', '');
    // let data = {"Domain":"http://221.226.59.30:7002","LoginName":"admin","Password":"123456"};
    // axios
    //     .post("api/terminal/User/Login",data,{headers: {'Referer': 'http://221.226.59.30:7002'}})
    //     .then(res => {
    //       if(res.data){
    //         console.log('1')
    //       }
    //     })
  },

  methods: {
    // 修改密码
    changePassword() {
      // let _this = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          changePassword(this.ruleForm).then((res) => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              this.changePasswordVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    over1point() {
      this.isClick1 = true;
      this.isClick2 = false;
      this.isClick3 = false;
    },
    over2point() {
      this.isClick1 = false;
      this.isClick2 = true;
      this.isClick3 = false;
    },
    over3point() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = true;
    },
    clickSelect1(e) {
      e.stopPropagation();
      this.isShowSelect1 = !this.isShowSelect1;
    },
    clickSelect2(e) {
      e.stopPropagation();
      this.isShowSelect2 = !this.isShowSelect2;
    },
    falseAll() {
      this.isShowSelect1 = false;
      this.isShowSelect2 = false;
      // this.isShowSelectInterval = false;
    },
    clickOptionMap(item) {
      this.mapName = item;
    },
    changePassord() {
      this.changePasswordVisible = true;
      this.$refs["ruleForm"].resetFields();
    },
    clickOption1(item) {
      // this.projectName = item.projectName;
      this.mutationsChangeName(item.projectName);
      this.mutationsChangeId(item.projectId);
      setProject(item.projectId).then((res) => {
        if (res.data.code === 0) {
          // this.mutationsChangeId(item.projectId);
          window.location.reload();
        }
      });
    },
    clickOption2(item) {
      this.languageP = item;
    },
    collapaseMenu() {
      this.isCollapseM = !this.isCollapseM;
      this.isCollapse = !this.isCollapse;
      if (this.isCollapseM) {
        this.collapseImg = `${require("@/assets/展开.png")}`;
      } else {
        this.collapseImg = `${require("@/assets/收起.png")}`;
      }
    },
    showFull() {
      this.isCollapseM = !this.isCollapseM;
      this.isCollapse = !this.isCollapse;
      if (this.isCollapseM) {
        this.collapseImg = `${require("@/assets/展开.png")}`;
        this.$store.commit("mutationsShowProject");
      } else {
        this.collapseImg = `${require("@/assets/收起.png")}`;
        this.$store.commit("mutationsShowProject");
      }
    },
    click1point() {
      this.isClick1 = true;
      this.isClick2 = false;
      this.isClick3 = false;
      this.showModal = true;
    },
    click2point() {
      this.isClick1 = false;
      this.isClick2 = true;
      this.isClick3 = false;
      this.showModal = true;
    },
    click3point() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = true;
      this.showModal = true;
    },
    clearAll() {
      this.isClick1 = false;
      this.isClick2 = false;
      this.isClick3 = false;
      this.showModal = false;
    },
    closeScreenFull() {
      // this.isBigScreen = false;
      // this.mutationsHideBig()
      this.$store.commit("mutationsHideBig");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showLogout() {
      this.isLogout = true;
    },
    hideLogout() {
      this.isLogout = false;
    },
    logOut() {
      // let data = {};
      // axios
      //   .post("http://192.168.2.102:8088/api/auth/logout", data, {
      //     headers: {
      //       Authorization: localStorage.getItem("accessToken"),
      //     },
      //   })
      loginOut()
        .then((res) => {
          if (res.data) {
            if (res.data.code === 0) {
              this.$message.info("退出登录");
              console.log("1");
              localStorage.removeItem("accessToken");
              location.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg);
            } else {
              this.$message.error("系统未知错误");
            }
          }
        })
        .catch((error) => {
          this.$message.error("系统未知错误");
          console.log(error);
        });
    },
    init() {
      this.current = this.$route.name;
      this.currentKey = this.$route.name;
      console.log("1111", this.$route);
      console.log("2222", this.current);
      console.log("3333", this.currentKey);
      if (this.currentKey == "basicInfo") {
        this.isSide = false;
      } else {
        this.currentKey = this.$route.meta.one;
      }
    },
    handleClick(e) {
      console.log("click ", e);
      this.current = e.key;
      if (e.key == "channelData") {
        this.$router.push({ name: "channelData" });
      } else if (e.key == "original") {
        this.$router.push({ name: "original" });
      }
    },
    handleClickOne(e) {
      console.log("click", e);
      console.log(this.$route.name);
      this.currentKey = e.key;
      if (e.key == "dataManagement") {
        this.current = "channelData";
        this.isSide = true;
        this.$router.push({ name: "channelData" });
      } else if (e.key == "basicInfo") {
        this.isSide = false;
        this.$router.push({ name: "basicInfo" });
      }
    },
    toProjectOverall() {
      this.$router.push({ name: "overall" });
    },
    changeTheme(checked) {
      this.theme = checked ? "dark" : "light";
    },
    clickNH(index) {
      this.isActivenNH = index;
      this.dataUsed = [];
      if (index == 1) {
        this.isNHshow = false;
        this.unit2 = "(kWh)";
        getYearChart().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          // this.dataUsed[3] = '10';
          // this.dataUsed = [
          //   220,
          //   182,
          //   191,
          //   234,
          //   290,
          //   330,
          //   310,
          //   123,
          //   442,
          //   321,
          //   90,
          //   579,
          // ];
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 2) {
        this.isNHshow = false;
        this.unit2 = "(m3)";
        getYearChartWater().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 3) {
        this.isNHshow = false;
        this.unit2 = "(m3)";
        getYearChartAir().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      } else if (index === 4) {
        this.isNHshow = false;
        this.unit2 = "(kWh)";
        getYearChartSteam().then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            this.isNHshow = true;
            res.data.data.forEach((item) => {
              this.dataUsed.push(item.formatValue);
            });
          }
          console.log("this.dataUsed", this.dataUsed);
        });
      }
    },
    clickGN(index) {
      this.isActivenGN = index;
    },
    resizeHandler() {
      // this.$refs.chart1.resize();
      // this.$refs.chart2.resize();
      // this.$refs.chart3.resize();
      // this.$refs.chart4.resize();
      // this.$refs.chart5.resize();
      // this.$refs.chart6.resize();
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    ...mapMutations([
      "mutationsShowBig",
      "mutationsShowProject",
      "mutationsHideProject",
      "mutationsHideBig",
      "mutationsChangeId",
      "mutationsChangeArray",
      "mutationsChangeName",
    ]),
  },

  watch: {
    $route(to) {
      // debugger
      this.activeIndex = to.name;
      if (
        to.name == "actual" ||
        to.name == "equipSys" ||
        to.name == "elecMonitor"
      ) {
        this.isPadding = false;
      } else {
        this.isPadding = true;
      }
      if (
        to.name == "actual" ||
        to.name == "equipSys" ||
        to.name == "elecMonitor" ||
        to.name == "home" ||
        to.name == "overall" ||
        to.name == "heatingSystem" ||
        to.name == "heatingSystemJk" ||
        to.name == "coldSystemJk" ||
        to.name == "projectOverallZGC" ||
        to.name == "workshopMonitor" ||
        to.name == "workshopMonitor2" ||
        this.$route.name == "actualTimeWorkshopMonitor" ||
        this.$route.name == "actualTimeWorkshopMonitor2"
      ) {
        this.isBgDeep = true;
      } else {
        this.isBgDeep = false;
      }
      // else{
      //   debugger
      //   this.isSide = true;
      //   this.current = to.name;
      // }
    },
    // $route: {
    //   handler: function (val) {
    //     console.log(val);
    //   },
    //   // 深度观察监听
    //   deep: true,
    // },
  },
};
</script>
<style scoped>
.el-submenu >>> .el-submenu__title i {
  font-size: 16px;
  color: white;
  opacity: 0.8;
}
.el-menu {
  border: none !important;
}

.el-menu-item span {
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 16px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
  height: 100%;
  padding-bottom: 20px;
}

.header {
  height: 96px;
  width: 100%;
  background: #082254;
  box-shadow: 2px 2px 10px 0px rgba(8, 34, 84, 0.2);
  /* padding-left: 30px;
  padding-right: 30px; */
  position: relative;
  display: flex;
  align-items: center;
}
.header-content {
  width: 250px;
  flex: none;
  padding-left: 40px;
}
.isCollapseTHeader {
  width: 64px;
  flex: none;
  padding-left: 40px;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.isCollapseFHeader {
  width: 250px;
  flex: none;
  padding-left: 40px;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.isCollapseTBody {
  width: 64px;
  flex: none;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.isCollapseFBody {
  width: 250px;
  flex: none;
  height: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.header-content-left {
  height: 96px;
  line-height: 96px;
  /* float: left; */
}
.header-content-right {
  flex: 1;
  text-align: center;
  height: 96px;
  line-height: 96px;
  position: relative;
  /* display: flex;
  align-items: center; */
}
.header-content-left span {
  margin-left: 10px;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 24px;
  font-weight: bold;
}
.user-name {
  position: absolute;
  top: 19px;
  right: 20px;
  color: #fff;
  font-size: 16px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  cursor: pointer;
  z-index: 9999;
  height: 64px;
}
.down-menu {
  position: absolute;
  top: 70px;
  right: 0;
  background: #001529;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  width: 120px;
  color: white;
  z-index: 9999;
  cursor: pointer;
}
</style>
<style scoped>
.big-screen {
  background: url("~@/assets/bigBG.png");
  background-size: cover;
  position: relative;
}
.select-class >>> .el-input__inner {
  border-radius: 4px;
  border: 1px solid #3f6df8;
  border-color: rgba(63, 109, 248, 0.5);
  background: transparent;
}
.select-class >>> .el-select__caret {
  color: rgba(63, 109, 248, 0.5);
}
.square-1 {
  padding: 20px;
  padding-top: 10px;
  width: 401px;
  height: 255px;
  position: absolute;
  top: 85px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-2 {
  padding: 20px;
  width: 401px;
  height: 317px;
  position: absolute;
  top: 362px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-3 {
  padding: 20px;
  width: 401px;
  height: 217px;
  position: absolute;
  top: 702px;
  left: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-4 {
  padding: 20px;
  width: 401px;
  height: 206px;
  position: absolute;
  top: 85px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-5 {
  padding: 20px;
  width: 401px;
  height: 317px;
  position: absolute;
  top: 312px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-6 {
  padding: 20px;
  width: 401px;
  height: 267px;
  position: absolute;
  top: 652px;
  right: 30px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(63, 109, 248, 0.5);
}
.square-number {
  display: inline-block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 24px;
  border: 1px solid #3f6df8;
  border-color: rgba(63, 109, 248, 0.5);
  color: #ffffff;
  margin-right: 10px;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: white;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
}
.activeGN {
  background: #3f6df8;
}
.isPaddingTBody {
  padding: 20px;
  padding-bottom: 0;
}
.isPaddingFBody {
  padding: 0;
}
.islink {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #203865;
}
.islink .backg {
  padding: 0;
}
.isBgDeepT {
  background: #203865;
}
.isBgDeepF {
  background: #f4f7fc;
}

.el-submenu >>> .el-menu--inline li {
  background: rgba(6, 28, 70, 0.64) !important;
}
.is-active {
  background: #3f6df8 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}
.el-submenu >>> .el-menu--inline .is-active {
  background: #3f6df8 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}
.el-menu-item:hover {
  background: #3f6df8 !important;
}
.el-submenu >>> .el-submenu__title:hover {
  background: #3f6df8 !important;
}
.el-submenu >>> .el-menu--inline li:hover {
  background: #3f6df8 !important;
}
.sel-option {
  width: 100%;
  height: 32px;
  line-height: 32px;
  /* padding-left: 15px; */
  color: #bcccff;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}
.sel-option:hover {
  background: #3f6df8;
  color: white;
}
.activeOption1 {
  background: #3f6df8;
  color: white;
}
.dialog-footer .el-button--primary {
  background-color: #3f6df8 !important;
  border-color: #3f6df8 !important;
  margin-left: 40px !important;
}
</style>
<style>
/* .el-menu--vertical ul li {
  background: rgba(6, 28, 70, 0.64) !important;
}
.el-menu--vertical ul .is-active {
  background: #3f6df8 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
} */
.el-menu-vertical-demo::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.el-menu-vertical-demo::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  background-color: #082254;
  /* background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ); */
}
.el-menu-vertical-demo::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  background: #ffffff;
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #082254;
  /* background-image: -webkit-linear-gradient( 45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent ); */
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #3f6df8;
  /* background-image: -webkit-linear-gradient( 45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent ); */
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #3f6df8;
  /* background-image: -webkit-linear-gradient( 45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent ); */
}
</style>
